.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #1e3b47;
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  width: 40%;
}

.header {
  composes: heading-2 from './theme.module.css';
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  margin-top: 16px;
}

.description {
  font-family: 'DM Sans', serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  color: rgba(255, 255, 255, 0.85);
  padding: 32px 0 48px 0;
}

.primaryButton {
  composes: button-2 from './theme.module.css';
  composes: primaryButton from './theme.module.css';
  width: 171px;
  height: 64px;
  border-radius: 14px;
}

@media (max-width: 960px) {
  .mainContent {
    width: 70%;
  }
}
